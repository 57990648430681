.appointment-page__column {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 12px 0;
  border-bottom: 1px solid #e0e0e9;
}

.appointment-page__column:last-child {
  border-bottom: none;
}

.appointment-page__column-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #9bb79b;
  opacity: 0;
}

.appointment-page__column:hover {
  background: #f0f0f9;
  cursor: pointer;
}

.appointment-page__column_clicked > .appointment-page__column-background {
  animation: flash 500ms ease-in;
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.appointment-page__column-name {
  z-index: 1;
  min-width: 280px;
  max-width: 280px;
  font-weight: 600;
}

.appointment-page__column-value {
  z-index: 1;
}

.appointment-page__show-extra-button {
  display: inline-block;
  margin: 16px 0;
  padding: 10px;
  color: #9999aa;
  font-weight: 600;
  background: #eeeef6;
  border-radius: 8px;
  cursor: pointer;
  opacity: 0.7;
}

.appointment-page__create-time {
  color: #777788;
  font-size: 14px;
}

.appointment-page__subheader {
  padding-top: 30px;
}

.appointment-page__audio {
  padding: 12px 0;
}

.appointment-page__audio-controls {
  width: 100%;
}

.appointment-page__protocol-header {
  padding-top: 16px;
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 24px;
}

.appointment-page__group-header {
  font-weight: 700;
  font-size: 20px;
}

.appointment-page__group {
  padding-top: 40px;
}

.appointment-page__delete-button {
  margin: 20px 0;
  padding: 12px 24px;
  color: #333344;
  font-weight: bold;
  font-size: 16px;
  background-color: #e4e4ec;
  border: none;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
}

.appointment-page__delete-button:hover {
  color: white;
  background-color: #ff4d4f;
}

.appointment-page__delete-button:active {
  color: white;
  background-color: #c93d3f;
}
