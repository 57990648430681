.appointment-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  padding: 16px 20px;
  background: #eeeef6;
  border-radius: 10px;
  cursor: pointer;
}

.appointment-list-item:hover {
  background: #dddded;
}

.appointment-list-item__title {
  font-weight: bold;
  font-size: 16px;
}

.appointment-list-item__date {
  padding-left: 8px;
  color: #777788;
  font-size: 14px;
}

.appointment-list-item__status {
  flex-grow: 1;
  font-weight: 700;
  font-size: 16px;
  font-family: monospace;
  text-align: end;
}
