.content-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f4f4f9;
}

.content-column__container {
  width: 800px;
  min-height: 100vh;
  padding: 0 20px;
  background: white;
  border-right: 1px solid #eeeef6;
  border-left: 1px solid #eeeef6;
}
